import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../../components/PageWrapper";
import SEO from '../../components/SEO';
import metaImg from '../../assets/image/metaimg/default.jpg';
const Faq = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Frequently Asked Questions"
          description="Find out all you need to know about Digma's Interactive video Player"
          image={metaImg}
        />           
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>              
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/faq">FAQ</Link>
              </li>
              </ol>
           </nav>
        </div>            
         <div className="pt-4 pt-md-6 pt-lg-10 pb-13 pb-md-18 pb-lg-23">
          <div className="container">         
            {/* Section Title */}
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-9">
                <div className="text-center mb-13 mb-lg-18">
                  <h1 className="font-size-11 mb-7">
                    Frequently Asked Questions
                  </h1>
                  <h2 className="font-size-7 mb-0 px-md-10 px-xl-13">
                    Any other questions you may have please don't hestitate to <Link to="/contact" className="">Contact Us</Link> and we will respond asap.
                  </h2>
                </div>
              </div>
            </div>
            {/* End Section Title */}
            {/* Faqs */}
            {/* Faqs */}
            <div className="row justify-content-center">
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h3 className="font-size-7 mb-7">
                    How is it integrated into the website?
                    </h3>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">Integration is very simple, Just two lines of code. The first line of code is the HTML tag, which is pasted where you would like the player to sit on your website. The Second line of code is the Javascript tag which you place before the 'body' tag on your website.</p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h4 className="font-size-7 mb-7">
                    Can we use YouTube or Vimeo videos?
                    </h4>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      Digma is a video hosting platform in itself. Digma doesnt integrate with Youtube or Vimeo as it is in breach of their terms and conditions.
                    </p>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                      If you have YouTube or Vimeo we can extract the video files for you and the files will be hosted on our servers.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h5 className="font-size-7 mb-7">
                    How long do we have the Digmatized video for?
                    </h5>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">As long as you like. There is a minimum of 1 year contract with Digma. </p>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">If you wish to end your subscription before then, there will be a termination fee discussed in the Terms of Service.</p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
              {/* Single Faq */}
              <div className="col-lg-6 col-md-10">
                <div className="d-flex mb-10 mb-lg-17">
                  <div className="mr-6">
                    <div className="bg-light-orange circle-28 text-white mt-1">
                      <i className="fa fa-question" />
                    </div>
                  </div>
                  <div className="">
                    <h6 className="font-size-7 mb-7">
                    Can I edit my videos?
                    </h6>
                    <p className="font-size-5 pr-md-10 pr-lg-0 mb-0">
                    You will have full access to edit your videos. If you wish to have one video contract but edit the contents or change video every few months, that is at your discretion.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Faq */}
            </div>
            {/* Button  */}
            <div className="text-center pt-lg-3">
              <p className="font-size-6 mb-0">
                Still have a question about our service?
                <Link to="/contact"
                  className=" ml-5"
                >
                  Contact us
                </Link>
              </p>
            </div>
            {/* Button End */}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Faq;
